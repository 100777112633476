import React from "react"
import Layout from "../../component/layout";
import Meta from "../../component/meta"

export default ({location}) => (
<React.Fragment>
<Meta
  title="Рома Гуйван: Кто по жизни"
  lang="ru"
  keywords={[]}></Meta>
<Layout language={ location.pathname.split('/')[1] }>
    <h1>Кто по-жизни </h1>
    <p>Рома, {new Date().getFullYear() - 1993}, люблю <a href="https://en.wikipedia.org/wiki/Tony_Hawk%27s_Pro_Skater_3">Pro Skater 3</a>, <a href="http://www.deftones.com/">Deftones</a>, книжки с картинками и батл-реп отца нашего <a href="https://twitter.com/hyde_mj?lang=en">Хайда</a>.</p>    
    <h2>Социалочка</h2>
    <ul>
        <li> <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/roman.guivan">Facebook для тех кто хочет дружить</a>  </li>
        <li> <a target="_blank" rel="noopener noreferrer" href="https://stackoverflow.com/users/4091861/%D0%A0%D0%BE%D0%BC%D0%B0%D0%BD-%D0%93%D1%83%D0%B9%D0%B2%D0%B0%D0%BD">Мой аккаунт Stackoverflow</a>  </li>
        <li> <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/pisjun_pisjun">Фоточки в инстаграм </a>  </li>
        <li> <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1gxe_4CFtxIE2fxwOysoPrXaC95Q2k2Kr7muPHMWINoo/edit?usp=sharingn">Табличка комиксанов какие читаю/ХРАНЮ!</a>  </li>
        <li> <a target="_blank" rel="noopener noreferrer" href={"/"+location.pathname.split('/')[1] + "/games"}>Игры какие я играл и мои МЫСЛИ</a>  </li>
    </ul>
    <h2>Пользовательское соглашение и политика партии для интеграции с facebook</h2>
    <p>Нет никаких, пишите коменты, мне кажется это прикольно. Обидные может буду удалять, а может и не буду!</p>
    <p>Где-то здесь потом еще будет способ послушать мои аудиозаписи, любимые фильмы и сколько я золотых монеток собрал в игоры</p>
</Layout>
</React.Fragment>)